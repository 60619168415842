import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useSelector } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import { useIsMobile } from "../../../common/utils"
import { selectCurrentBrokerage } from "../slice"

export default function ConfirmSendAllCandidatesToCRMDialog({
  open,
  onClose,
  totalAgentCount,
}) {
  const [isSending, setIsSending] = useState(false)
  const axios = useAuthorizedAxiosClient()
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const isMobile = useIsMobile()

  function handleSoftClose() {
    onClose()
  }

  function handleSendButtonClicked() {
    setIsSending(true)
    axios
      .post(`/customers/api/${currentBrokerage.id}/crm/send-candidates/`)
      .then(() => onClose(true))
      .finally(() => setIsSending(false))
  }

  return (
    <Dialog
      open={open}
      onClose={handleSoftClose}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ gap: 2 }}>
          <Column sx={{ alignItems: "center", gap: 3 }}>
            <HaloCircularIcon Icon={DriveFileMoveOutlinedIcon} size={64} />
            <Typography variant="h6">
              Send {totalAgentCount} agents to SONAR CRM
            </Typography>
          </Column>
          <Typography>
            This might take a few minutes to finish. Press <strong>Send</strong>{" "}
            to continue.
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleSoftClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isSending}
          onClick={handleSendButtonClicked}
        >
          Send to SONAR CRM
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
