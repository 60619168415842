import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import { useState } from "react"
import { useSelector } from "react-redux"

import { useAuthorizedAxiosClient } from "../../common/utils"
import { selectHasSignedUpOnCRM } from "../brokerage/slice"

export default function SendToCRMButton({ candidate, onCandidateUpdated }) {
  const [isLoading, setIsLoading] = useState(false)
  const axios = useAuthorizedAxiosClient()
  const hasSignedUpOnCRM = useSelector(selectHasSignedUpOnCRM)

  function handleViewInCRMButtonClicked(e, candidate) {
    e.stopPropagation()
    window
      .open(
        new URL(
          `/candidates/${candidate.crm_candidate_id}`,
          process.env.REACT_APP_CRM_FRONTEND_URL
        ).toString(),
        "_blank"
      )
      .focus()
  }

  function handleSendToCRMButtonClicked(e, candidate) {
    e.stopPropagation()
    setIsLoading(true)
    axios
      .post(`/customers/api/crm/send-candidates/${candidate.id}/`)
      .then(({ data }) => {
        onCandidateUpdated({ ...candidate, ...data })
      })
      .finally(() => setIsLoading(false))
  }

  return candidate.crm_candidate_id ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={(e) => handleViewInCRMButtonClicked(e, candidate)}
    >
      View in CRM
    </Button>
  ) : (
    <LoadingButton
      variant="contained"
      color="primary"
      size="small"
      loading={isLoading}
      disabled={!hasSignedUpOnCRM}
      onClick={(e) => handleSendToCRMButtonClicked(e, candidate)}
    >
      Send to CRM
    </LoadingButton>
  )
}
