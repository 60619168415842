import CircleIcon from "@mui/icons-material/Circle"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"
import { useLocation } from "react-router-dom"

import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import BrokerageIcon from "../../../common/resources/icons/brokerage-gray.png"
import { formatCurrency } from "../../../common/utils"
import { AGENT_LEVELS_OF_INTEREST } from "../../agent/utils"
import SendToCRMButton from "../../common/SendToCRMButton"
import AgentNameBlueCheck from "../components/AgentNameBlueCheck"
import AgentStatusChip from "../components/AgentStatusChip"
import AgentToMarketStatComparison, {
  avgSalePriceTooltip,
  buildStatTitle,
  homesSoldTooltip,
  salesVolumeTooltip,
  yearsInBusinessTooltip,
} from "../components/AgentToMarketStatComparison"
import BlurMissingIconTitleValue from "../components/BlurMissingIconTitleValue"
import Perk from "../components/Perk"
import TopProducerAgentAvatar from "../components/TopProducerAgentAvatar"
import { MARKET_PROGRESSBAR_COLOR } from "../components/VerticalProgressBars"
import {
  AGENT_FIELD_HIDDEN_MARKER,
  useCandidateStatsLoader,
  useStartAgentConversation,
} from "../utils"
import CandidateDrawer, {
  BaseballCardSectionDivider,
  CloseIconButton,
  NotesEditor,
} from "./CandidateDrawerCommon"

export default function BaseballCardDrawer({
  highlightedCandidate,
  setHighlightedCandidate,
  onCandidateUpdated,
}) {
  const theme = useTheme()
  const { isCreatingConversation, requestStartConversation } =
    useStartAgentConversation()
  const { stats } = useCandidateStatsLoader()
  const location = useLocation()
  const isInboxPage = location.pathname.indexOf("conversations") > -1
  const perks = highlightedCandidate?.agent?.perks

  function handleSendMessageButtonClicked() {
    requestStartConversation(highlightedCandidate)
  }

  function handleSendToCRMButtonCandidateUpdated(candidate) {
    setHighlightedCandidate(candidate)
    onCandidateUpdated(candidate)
  }

  function LabeledCircle({ label, color, addMarginLeft }) {
    return (
      <Row
        sx={{
          alignItems: "center",
          ml: addMarginLeft ? 1 : 0,
        }}
      >
        <CircleIcon sx={{ color: color, width: "8px" }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {label}
        </Typography>
      </Row>
    )
  }

  return (
    <CandidateDrawer
      highlightedCandidate={highlightedCandidate}
      setHighlightedCandidate={setHighlightedCandidate}
    >
      {highlightedCandidate && (
        <Column>
          <Column
            sx={{
              p: { xs: 2, sm: 4 },
            }}
          >
            <Column>
              <Row
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Row
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <TopProducerAgentAvatar
                    agentRequest={highlightedCandidate}
                    avatarSize={64}
                  />
                  <Column
                    sx={{
                      ml: 2,
                    }}
                  >
                    <RowButColumnOnMobile
                      sx={{
                        alignItems: { xs: "flex-start", sm: "center" },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          mr: { xs: 0, sm: 1 },
                          mt: { xs: 1, sm: 0 },
                          order: { xs: 1, sm: 0 },
                        }}
                      >
                        <AgentNameBlueCheck
                          agentRequest={highlightedCandidate}
                        />
                      </Typography>
                      <Box sx={{ order: { xs: 0, sm: 1 } }}>
                        <AgentStatusChip agent={highlightedCandidate} />
                      </Box>
                    </RowButColumnOnMobile>
                    <Row
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <TravelExploreOutlinedIcon
                        sx={{ width: "16px", color: "text.secondary2" }}
                      />
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {AGENT_LEVELS_OF_INTEREST[
                          highlightedCandidate.agent.level_of_interest
                        ] || "Unknown level of interest"}
                      </Typography>
                    </Row>
                  </Column>
                </Row>
                <CloseIconButton
                  setHighlightedCandidate={setHighlightedCandidate}
                />
              </Row>
              {!isInboxPage && (
                <Column sx={{ gap: 2 }}>
                  <LoadingButton
                    sx={{ mt: 4 }}
                    fullWidth
                    isLoading={isCreatingConversation}
                    onClick={handleSendMessageButtonClicked}
                  >
                    Send message
                  </LoadingButton>
                  <SendToCRMButton
                    candidate={highlightedCandidate}
                    onCandidateUpdated={handleSendToCRMButtonCandidateUpdated}
                  />
                </Column>
              )}
            </Column>
            <BaseballCardSectionDivider />
            <Row
              sx={{
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Performance</Typography>
              <Tooltip
                title={
                  <Row sx={{ alignItems: "center" }}>
                    <LabeledCircle
                      color={MARKET_PROGRESSBAR_COLOR}
                      label="Market average"
                    />
                    <LabeledCircle
                      color={theme.palette.secondary.main}
                      label="Candidate"
                      addMarginLeft
                    />
                  </Row>
                }
                placement="right"
                arrow
              >
                <InfoOutlinedIcon sx={{ color: "text.secondary2", ml: 1 }} />
              </Tooltip>
            </Row>
            <Box
              sx={{
                mt: 4,
              }}
            >
              <Row
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BaseballCardStatComparison
                  agentValue={highlightedCandidate.years_in_business}
                  marketValue={stats.avg_years_in_business}
                  title={buildStatTitle(highlightedCandidate.years_in_business)}
                  subtitle="Total years in business"
                  tooltip={yearsInBusinessTooltip(stats.avg_years_in_business)}
                />
                <BaseballCardStatComparison
                  agentValue={highlightedCandidate.sales_volume_last_year}
                  marketValue={stats.avg_sales_volume_last_year}
                  title={buildStatTitle(
                    highlightedCandidate.sales_volume_last_year,
                    (salesVolume) => formatCurrency(salesVolume)
                  )}
                  subtitle="Total sales volume last year"
                  tooltip={salesVolumeTooltip(stats.avg_sales_volume_last_year)}
                />
              </Row>
              <Row
                sx={{
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <BaseballCardStatComparison
                  agentValue={highlightedCandidate.homes_sold_last_year}
                  marketValue={stats.avg_homes_sold_last_year}
                  title={buildStatTitle(
                    highlightedCandidate.homes_sold_last_year
                  )}
                  subtitle="Homes sold last year"
                  tooltip={homesSoldTooltip(stats.avg_homes_sold_last_year)}
                />
                <BaseballCardStatComparison
                  agentValue={highlightedCandidate.avg_sale_price}
                  marketValue={stats.avg_price_per_sale}
                  title={buildStatTitle(
                    highlightedCandidate.avg_sale_price,
                    (salePrice) => formatCurrency(salePrice)
                  )}
                  subtitle="Average sale price per home"
                  tooltip={avgSalePriceTooltip(stats.avg_price_per_sale)}
                />
              </Row>
            </Box>
            <BaseballCardSectionDivider />
            <Typography variant="h6">About me</Typography>
            <Box
              sx={{
                mt: 4,
              }}
            >
              <BlurMissingIconTitleValue
                Icon={() => (
                  <img
                    src={BrokerageIcon}
                    alt="Brokerage"
                    style={{ width: "24px" }}
                  />
                )}
                title="Current Brokerage"
                value={highlightedCandidate.job_title}
                missingPlaceholder="Brokerage Office Currently Hidden"
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                mt: 4,
                whiteSpace: "break-spaces",
                filter: (theme) =>
                  highlightedCandidate.mission_statement ===
                    AGENT_FIELD_HIDDEN_MARKER && theme.units.textBlur,
              }}
            >
              {highlightedCandidate.mission_statement ===
              AGENT_FIELD_HIDDEN_MARKER
                ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                : highlightedCandidate.mission_statement}
            </Typography>
            <BaseballCardSectionDivider />
            <Typography variant="h6">Contact info</Typography>
            <Box
              sx={{
                "& > .MuiBox-root": {
                  mt: 4,
                },
              }}
            >
              <BlurMissingIconTitleValue
                Icon={PhoneIcon}
                title="Phone Number"
                value={highlightedCandidate.phone}
                missingPlaceholder="+1 123456789"
              />
              <BlurMissingIconTitleValue
                Icon={LanguageOutlinedIcon}
                title="Website"
                value={highlightedCandidate.website}
                missingPlaceholder="http://www.example.com/"
              />
              <BlurMissingIconTitleValue
                Icon={EmailIcon}
                title="Email"
                value={highlightedCandidate.email}
                missingPlaceholder="test@example.com"
              />
              <BlurMissingIconTitleValue
                Icon={LocationOnOutlinedIcon}
                title="Location"
                value={highlightedCandidate.address}
                missingPlaceholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
              />
            </Box>
            <BaseballCardSectionDivider />
            <Typography variant="h6">Looking for</Typography>
            <Column
              sx={{ mt: 4, "& > .MuiBox-root:nth-of-type(n + 2)": { mt: 2 } }}
            >
              {perks.length === 0 ? (
                <Typography variant="body1" color="text.secondary2">
                  Candidate doesn't seem to be looking for specific job perks.
                </Typography>
              ) : (
                perks.map((perk) => <Perk key={perk.id} perk={perk} />)
              )}
            </Column>
            <BaseballCardSectionDivider />
            <NotesEditor
              highlightedCandidate={highlightedCandidate}
              onCandidateUpdated={onCandidateUpdated}
            />
          </Column>
        </Column>
      )}
    </CandidateDrawer>
  )
}

function BaseballCardStatComparison({
  agentValue,
  marketValue,
  title,
  subtitle,
  tooltip,
}) {
  return (
    <AgentToMarketStatComparison
      agentValue={agentValue}
      marketValue={marketValue}
      title={title}
      subtitle={subtitle}
      tooltip={tooltip}
      titleVariant="h6"
    />
  )
}

export function useBaseballCardState() {
  const [highlightedCandidate, setHighlightedCandidate] = useState(null)
  return {
    highlightedCandidate,
    setHighlightedCandidate,
  }
}
