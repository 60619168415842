import { createSlice } from "@reduxjs/toolkit"

import { TOUR_STATUS } from "../../common/components/CommonTourProvider"
import {
  createRetrieveProfileAsyncThunk,
  createStorageKey,
  segmentAnalytics,
} from "../../common/utils"

const retrieveBrokerageProfile = createRetrieveProfileAsyncThunk(
  "brokerage/retrieveProfile",
  "/customers/api/profile/",
  "customer"
)

const currentBrokerageIndexStorageKey = createStorageKey(
  "currentBrokerageIndex"
)

export const NOTIFICATIONS = {
  dashboardCardInfo: "dashboardCardInfo",
}

const brokerageSlice = createSlice({
  name: "brokerage",
  initialState: {
    profile: {
      brokerages: [],
      airtable_campaign_names: [],
    },
    profileLoading: true,
    currentBrokerageIndex:
      localStorage.getItem(currentBrokerageIndexStorageKey) || 0,
    notifications: {
      [NOTIFICATIONS.dashboardCardInfo]: true,
    },
    proDialog: {
      open: false,
      brokerageIndex: 0,
      newBrokerage: false,
    },
  },
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload
    },
    updateProfileProperty: (state, action) => {
      state.profile = { ...state.profile, ...action.payload }
    },
    updateBrokerage: (state, action) => {
      const newBrokerageData = action.payload
      state.profile.brokerages = state.profile.brokerages.map((brokerage) =>
        brokerage.id === newBrokerageData.id ? newBrokerageData : brokerage
      )
    },
    updateCurrentBrokerage: (state, action) => {
      state.currentBrokerageIndex = state.profile.brokerages.findIndex(
        (brokerage) => brokerage.id === action.payload.id
      )
      updateStorageCurrentBrokerageIndex(state.currentBrokerageIndex)
    },
    addBrokerage: (state, action) => {
      state.profile.brokerages.push(action.payload)
    },
    updateBrokerageSubscription: (state, action) => {
      const { brokerageID, subscription } = action.payload
      state.profile.brokerages = state.profile.brokerages.map((brokerage) =>
        brokerage.id === brokerageID
          ? { ...brokerage, subscription }
          : brokerage
      )
    },
    decrementUnreadConversationsCount: (state) => {
      state.profile.unread_conversations_count -= 1
    },
    logoutBrokerage: (state) => {
      localStorage.removeItem(currentBrokerageIndexStorageKey)
    },
    hideNotification: (state, action) => {
      state.notifications[action.payload] = false
    },
    showProDialogForCurrentBrokerage: (state, action) => {
      showProDialog({
        state,
        brokerageIndex: state.currentBrokerageIndex,
        newBrokerage: false,
        source: action.payload,
      })
    },
    showProDialogForLatestBrokerage: (state) => {
      if (state.profile.brokerages.length === 1) {
        return
      }
      showProDialog({
        state,
        brokerageIndex: state.profile.brokerages.length - 1,
        newBrokerage: true,
        source: "Post claim",
      })
    },
    showProDialogForBrokerageIndex: (state, action) => {
      showProDialog({
        state,
        brokerageIndex: action.payload.index,
        newBrokerage: false,
        source: action.payload.source,
      })
    },
    hideProDialog: (state) => {
      state.proDialog.open = false
      if (state.showFirstBrokerageProDialog) {
        state.showFirstBrokerageProDialog = false
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveBrokerageProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.profileLoading = false
      // reset current brokerage index if out of bounds (especially for impersonating customers)
      if (state.currentBrokerageIndex >= state.profile.brokerages.length) {
        state.currentBrokerageIndex = 0
        updateStorageCurrentBrokerageIndex(0)
      }
    })
  },
})

function updateStorageCurrentBrokerageIndex(brokerageIndex) {
  localStorage.setItem(currentBrokerageIndexStorageKey, brokerageIndex)
}

function showProDialog({ state, brokerageIndex, newBrokerage, source }) {
  state.proDialog = {
    open: true,
    brokerageIndex,
    newBrokerage,
  }
  const brokerage = selectBrokerages({ brokerage: state })[brokerageIndex]
  segmentAnalytics.track("Pro subscription modal opened", {
    "Brokerage ID": brokerage.id,
    "Brokerage name": brokerage.company,
    Source: source,
  })
}

export const selectProfile = (state) => state.brokerage.profile

export const profileLoading = (state) => state.brokerage.profileLoading

export const selectBrokerages = (state) => selectProfile(state).brokerages

export const selectBrokerageCount = (state) => selectBrokerages(state).length

export const selectBrokerageByID = (state) => (id) =>
  selectBrokerages(state).find((brokerage) => brokerage.id === id)

export const selectCurrentBrokerageIndex = (state) =>
  state.brokerage.currentBrokerageIndex

export const selectCurrentBrokerage = (state) => {
  const brokerages = selectBrokerages(state)
  return brokerages[selectCurrentBrokerageIndex(state)]
}

export const selectShowDashboardInfoNotification = (state) =>
  state.brokerage.notifications.dashboardCardInfo

export const selectProDialog = (state) => state.brokerage.proDialog

export const selectShowDashboardTour = (state) =>
  selectProfile(state).brokerage_tour_status === TOUR_STATUS.new && false

export const selectHasCampaigns = (state) =>
  selectProfile(state).airtable_campaign_names.length > 0

export const selectIsForcedToPay = (state) => selectProfile(state).forced_to_pay

export const selectIsFreeRiding = (state) => selectProfile(state).free_riding

export const selectHasSignedUpOnCRM = (state) =>
  !!selectProfile(state).crm_user_id

export { retrieveBrokerageProfile }

export default brokerageSlice.reducer

export const {
  updateProfile,
  updateProfileProperty,
  updateBrokerage,
  updateCurrentBrokerage,
  addBrokerage,
  updateBrokerageSubscription,
  decrementUnreadConversationsCount,
  logoutBrokerage,
  hideNotification,
  showProDialogForBrokerageIndex,
  showProDialogForCurrentBrokerage,
  showProDialogForLatestBrokerage,
  hideProDialog,
} = brokerageSlice.actions
